<template>
  <main>
    <b-overlay :show="loading">
      <form-wizard
        color="#ff9801"
        :title="null"
        :subtitle="null"
        finish-button-text="Simpan"
        back-button-text="Sebelumnya"
        next-button-text="Selanjutnya"
        class="wizard-vertical mb-3"
        @on-complete="formSubmitted"
      >
        <!-- data no surat -->
        <tab-content title="Konsumen" :before-change="validationForm">
          <validation-observer ref="form">
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Pilih Sales"
                  label-for="v-karyawan_id"
                  v-if="this.userData.level.id != 7"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="karyawan_id"
                    :rules="{ required: true }"
                  >
                    <b-form-select
                      :state="errors.length > 0 ? false : null"
                      v-model="form.karyawan_id"
                      :options="karyawan_id"
                      id="v-karyawan_id"
                      name="karyawan_id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Pilih Sales"
                  label-for="v-karyawan_id"
                  v-if="this.userData.level.id == 7"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="karyawan_id"
                    :rules="{ required: true }"
                  >
                    <b-form-select
                      :state="errors.length > 0 ? false : null"
                      v-model="form.karyawan_id"
                      :options="karyawan_id"
                      id="v-karyawan_id"
                      name="karyawan_id"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group label="Tanggal & Waktu" label-for="v-tanggal">
                  <validation-provider
                    #default="{ errors }"
                    name="tanggal"
                    :rules="{ required: true }"
                  >
                    <flat-pickr
                      v-model="form.tanggal"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  label="Nama Customer / Konsumen"
                  label-for="v-nama_costumer"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nama_costumer"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="form.nama_costumer"
                      id="v-nama_costumer"
                      placeholder="Isi Nama Customer / Konsumen"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group label="Tipe Konsumen" label-for="v-tipe_konsumen">
                  <validation-provider
                    #default="{ errors }"
                    name="tipe_konsumen"
                    :rules="{ required: true }"
                  >
                    <!-- <flat-pickr
                      v-model="form.tipe_konsumen"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    /> -->
                    <b-form-select
                      :state="errors.length > 0 ? false : null"
                      v-model="form.tipe_konsumen"
                      :options="tipe_konsumen"
                      id="v-tipe_konsumen"
                      name="tipe_konsumen"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group label="Kecamatan" label-for="v-kecamatan">
                  <validation-provider
                    #default="{ errors }"
                    name="kecamatan"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      v-model="form.kecamatan"
                      id="v-kecamatan"
                      placeholder="Isi Kecamatan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Alamat" label-for="v-alamat">
                  <validation-provider
                    #default="{ errors }"
                    name="alamat"
                    rules="required"
                  >
                    <b-form-textarea
                      :state="errors.length > 0 ? false : null"
                      v-model="form.alamat"
                      id="v-alamat"
                      placeholder="Isi alamat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12">
                  <b-form-group label="Keterangan" label-for="v-keterangan">
                    <validation-provider
                      #default="{ errors }"
                      name="keterangan"
                      rules="required"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi Keterangan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
              <!-- <b-col sm="12">
                  <b-form-group label="Brand : Selling" label-for="v-brand_selling">
                    <validation-provider
                      #default="{ errors }"
                      name="brand_selling"
                      rules="required"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.brand_selling"
                        id="v-brand_selling"
                        placeholder="Rokok : 15000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- / -->

        <!-- List barang -->
        <tab-content title="Brand dan Harga">
          <b-button variant="info" class="mb-2" @click.prevent="addSellingNote">
            Tambah Brand
          </b-button>
          <b-table :fields="tableFields" :items="selectedBrands" hover bordered striped>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(add)>
              <b-button variant="primary" size="sm" @click.prevent="addList">
                <feather-icon icon="PlusCircleIcon"></feather-icon>
              </b-button>
            </template>
            <template #cell(brand)="{ item }">
              <b-form-input v-if="isSales" v-model="item.brand"></b-form-input>
              <p v-else>
                {{ item.brand }}
              </p>
            </template>
            <template #cell(qty)="{ item }">
              <b-form-input
                v-if="isSales"
                v-model="item.qty"
                type="number"
              ></b-form-input>
              <p v-else>
                {{ item.qty }}
              </p>
            </template>
            <template #cell(harga)="{ item, index }">
              <b-form-input
                v-if="isSales"
                v-model="item.harga"
                @keyup="doFormatRupiah({index})"
              ></b-form-input>
              <p v-else>
                {{ item.harga }}
              </p>
            </template>
            <template #cell(action)="{ index, item }">
              <b-button
                v-if="index > 0"
                size="sm"
                varian="danger"
                @click.prevent="remove(index, item)"
              >
                <feather-icon icon="XIcon"></feather-icon>
              </b-button>
            </template>
          </b-table>
        </tab-content>
        <!-- / -->
      </form-wizard>
    </b-overlay>
  </main>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import flatPickr from "vue-flatpickr-component";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BCard,
  BOverlay,
  BTooltip,
  BButton,
  BTable,
  BFormTextarea,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import "@core/scss/vue/libs/vue-flatpicker.scss";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("positive", (value) => {
  return value > 0;
});
const initValues = [
  {
    brand: "Brand A",
    harga: 0,
  },
  {
    brand: "Brand B",
    harga: 0,
  },
];
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    BFormSelect,
    BRow,
    BForm,
    flatPickr,
    BOverlay,
    BTable,
    BTooltip,
    BCard,
    BButton,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      addAction: true,
      initValues,
      loading: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      selectedBrands: [],
      form: {
        karyawan_id: "",
        tanggal: new Date(),
        nama_costumer: "",
        alamat: "",
        kecamatan: "",
        tipe_konsumen: 0,
        brand: [],
      },
      id: null,
      brand: "",
      harga: "",
      tipe_konsumen: [],
      karyawan_id: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      tableFields: [
        // {
        //   key: "no",
        //   label: "No",
        //   sortable: true,
        // },
        { key: "add", label: "#" },
        { key: "brand", label: "Brand", sortable: true },
        { key: "harga", label: "Harga / Selling", sortable: true },
        { key: "action", label: "" },
      ],
      id: null,
    };
  },
  methods: {
    doFormatRupiah({index}) {
      this.selectedBrands[index].harga = this.formatRupiah( this.selectedBrands[index].harga )
    },
    async remove(index, item) {
      if (!item.id) {
        this.selectedBrands.splice(index, 1);
      } else {
        await this.$store.dispatch("analisis/saveBrand", [{ id: item.id, fungsi: 2 }]);
        this.getAnalisis();
      }
    },
    addList() {
      this.selectedBrands.push({
        brand: "-",
        harga: 0,
      });
    },
    addSellingNote() {
      this.selectedBrands = [];
      this.addAction = true;
      this.selectedBrands = initValues;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFiltered() {},
    getsales_id() {
      this.$store
        .dispatch("karyawan/getData", { jabatan_id: 8 })
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });
          this.karyawan_id = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getsalesName(data) {
      let find = this.karyawan_id.find((item) => item.id == data.sales.id);
      return find ? find.brand_owner : "-";
    },
    gettipe() {
      this.$store
        .dispatch("tipekonsumen/getData", {})
        .then(() => {
          let tipekonsumen = JSON.parse(
            JSON.stringify(this.$store.state.tipekonsumen.datas)
          );
          tipekonsumen.map((item) => {
            item.value = item.id;
            item.text = item.nama;
          });
          this.tipe_konsumen = tipekonsumen;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getAnalisis() {
      // let params = {where:{id:this.$route.params.id}}
      const analisis = await this.$store.dispatch(
        "analisis/getDataById",
        this.$route.params.id
      );
      this.form.tanggal = analisis.tanggal;
      this.form.nama_costumer = analisis.nama_costumer;
      this.form.alamat = analisis.alamat;
      this.form.kecamatan = analisis.kecamatan;
      this.form.tipe_konsumen = analisis.tipe.id;
      this.selectedBrands = analisis.brand;
    },
    async formSubmitted() {
      let payload1 = {
        karyawan_id: this.form.karyawan_id,
        tanggal: this.form.tanggal,
        nama_costumer: this.form.nama_costumer,
        kecamatan: this.form.kecamatan,
        alamat: this.form.alamat,
        tipe_konsumen: this.form.tipe_konsumen,
        brand: this.selectedBrands,
      };
      if (this.$route.params.id) {
        payload1.id = this.$route.params.id;
      }

      payload1.brand.map((item) => {
        if(this.$route.params.id) {
          item.id_analisis = this.$route.params.id
        }
        item.harga = this.unFormatRupiah(item.harga)
      });

      try {
        this.loading = true;
        await this.$store.dispatch("analisis/save", [payload1]);
        this.loading = false;
        this.$router.push(`/analisis`);
        this.displaySuccess({
          message: "Analisis / Market Survey berhasil disimpan!",
        });
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    // },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.id) {
      this.form.karyawan_id = userData.karyawan.id;
    }
    if (this.$route.params.id) {
      //if edit action
      this.id = this.$route.params.id;
      const penerimaanBarang = this.$store.dispatch["analisis/getDataById"](
        this.$route.params.id
      );
      if (!penerimaanBarang) {
        this.displayError({
          message: "Data tidak ditemukan",
        });
        setTimeout(() => {
          this.$router.push({ name: "analisis" });
        }, 1000);
      }
      this.form = {
        karyawan_id: penerimaanBarang.karyawan_id,
        tanggal: penerimaanBarang.tanggal,
        nama_costumer: penerimaanBarang.namacostumer,
        alamat: penerimaanBarang.alamat,
        kecamatan: penerimaanBarang.kecamatan,
        tipe_konsumen: penerimaanBarang.kecamatan,
      };
      // this.form.id_analisis = penerimaanBarang.id;
      // this.form.gudang_id = penerimaanBarang.gudang_id;
      // this.form.no_invoice = penerimaanBarang.no_invoice;
      // this.form.tanggal_invoice = penerimaanBarang.tanggal_invoice;
      // this.form.tanggal_invoice = penerimaanBarang.tanggal_invoice;
      // this.form.tanggal_penerimaan = penerimaanBarang.tanggal_penerimaan;
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.id) {
      this.form.karyawan_id = userData.karyawan.id;
    }
    this.getsales_id();
    if(this.$route.params.id) {
      this.getAnalisis();
    }
    this.gettipe();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
