var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('form-wizard', {
    staticClass: "wizard-vertical mb-3",
    attrs: {
      "color": "#ff9801",
      "title": null,
      "subtitle": null,
      "finish-button-text": "Simpan",
      "back-button-text": "Sebelumnya",
      "next-button-text": "Selanjutnya"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Konsumen",
      "before-change": _vm.validationForm
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [this.userData.level.id != 7 ? _c('b-form-group', {
    attrs: {
      "label": "Pilih Sales",
      "label-for": "v-karyawan_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "karyawan_id",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.karyawan_id,
            "id": "v-karyawan_id",
            "name": "karyawan_id"
          },
          model: {
            value: _vm.form.karyawan_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "karyawan_id", $$v);
            },
            expression: "form.karyawan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 473597175)
  })], 1) : _vm._e(), this.userData.level.id == 7 ? _c('b-form-group', {
    attrs: {
      "label": "Pilih Sales",
      "label-for": "v-karyawan_id"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "karyawan_id",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.karyawan_id,
            "id": "v-karyawan_id",
            "name": "karyawan_id",
            "disabled": ""
          },
          model: {
            value: _vm.form.karyawan_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "karyawan_id", $$v);
            },
            expression: "form.karyawan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3441415281)
  })], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu",
      "label-for": "v-tanggal"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }
          },
          model: {
            value: _vm.form.tanggal,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tanggal", $$v);
            },
            expression: "form.tanggal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Customer / Konsumen",
      "label-for": "v-nama_costumer"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama_costumer",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama_costumer",
            "placeholder": "Isi Nama Customer / Konsumen"
          },
          model: {
            value: _vm.form.nama_costumer,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nama_costumer", $$v);
            },
            expression: "form.nama_costumer"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Konsumen",
      "label-for": "v-tipe_konsumen"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tipe_konsumen",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.tipe_konsumen,
            "id": "v-tipe_konsumen",
            "name": "tipe_konsumen"
          },
          model: {
            value: _vm.form.tipe_konsumen,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tipe_konsumen", $$v);
            },
            expression: "form.tipe_konsumen"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kecamatan",
      "label-for": "v-kecamatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kecamatan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kecamatan",
            "placeholder": "Isi Kecamatan"
          },
          model: {
            value: _vm.form.kecamatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kecamatan", $$v);
            },
            expression: "form.kecamatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alamat",
      "label-for": "v-alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "alamat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-alamat",
            "placeholder": "Isi alamat"
          },
          model: {
            value: _vm.form.alamat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "alamat", $$v);
            },
            expression: "form.alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Brand dan Harga"
    }
  }, [_c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addSellingNote($event);
      }
    }
  }, [_vm._v(" Tambah Brand ")]), _c('b-table', {
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.selectedBrands,
      "hover": "",
      "bordered": "",
      "striped": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref8) {
        var index = _ref8.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(add)",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addList($event);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusCircleIcon"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "cell(brand)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm.isSales ? _c('b-form-input', {
          model: {
            value: item.brand,
            callback: function callback($$v) {
              _vm.$set(item, "brand", $$v);
            },
            expression: "item.brand"
          }
        }) : _c('p', [_vm._v(" " + _vm._s(item.brand) + " ")])];
      }
    }, {
      key: "cell(qty)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm.isSales ? _c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function callback($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }) : _c('p', [_vm._v(" " + _vm._s(item.qty) + " ")])];
      }
    }, {
      key: "cell(harga)",
      fn: function fn(_ref11) {
        var item = _ref11.item,
          index = _ref11.index;
        return [_vm.isSales ? _c('b-form-input', {
          on: {
            "keyup": function keyup($event) {
              return _vm.doFormatRupiah({
                index: index
              });
            }
          },
          model: {
            value: item.harga,
            callback: function callback($$v) {
              _vm.$set(item, "harga", $$v);
            },
            expression: "item.harga"
          }
        }) : _c('p', [_vm._v(" " + _vm._s(item.harga) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref12) {
        var index = _ref12.index,
          item = _ref12.item;
        return [index > 0 ? _c('b-button', {
          attrs: {
            "size": "sm",
            "varian": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.remove(index, item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }